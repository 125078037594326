"use strict";

window.addEventListener('DOMContentLoaded', () => {
    initSlider();

    const container = document.querySelector('.animation-container');
    const items = document.querySelectorAll('.animation-item');

    document.addEventListener('mousemove', (e) => {
        const rect = container.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        if (y > 1500 || window.innerWidth < 992) {
            return;
        }

        items.forEach((item, index) => {
            const speed = (index + 1) / 100;
            const xMove = (x - rect.width / 2) * speed;
            const yMove = (y - rect.height / 2) * speed;

            item.style.transform = `translate(${xMove}px, ${yMove}px)`;
        });
    });

    if (window.domainFetchPromise) {
        window.domainFetchPromise.then(() => {
            //----Set active language----
            const lang = location.pathname.replace('.html', '').split('-')?.[1] ?? 'en';
            const element = document.querySelector(`.dropdown-link.${lang}`);
            const region = element.dataset.region;
            element?.classList?.add('active');

            const langBtn = document.getElementById('dropbtn');

            if (langBtn) {
                langBtn.classList.add(lang);
            }

            window.paramsString = addCookiesParams(createParamsString());
            setParamsToLinks(region);

            const yearElement = document.querySelector('.current-year');

            if (yearElement) {
                yearElement.textContent = new Date().getFullYear();
            }
        })
    }
});

window.addEventListener('scroll', (event) => {
    const header = document.querySelector("header");
    const toggleClass = "is-sticky";
    const currentScroll = window.pageYOffset;

    if (currentScroll > 150) {
        header.classList.add(toggleClass);
    } else {
        header.classList.remove(toggleClass);
    }
});

window.addEventListener('resize', (event) => {
    initSlider();
});

document.addEventListener("click", function (evt) {
    let flyoutEl = document.getElementById('dropbtn'),
        targetEl = evt.target,
        contentEL = document.getElementById('dropdown-content');

    if (!flyoutEl) {
        return;
    }

    if (targetEl == flyoutEl) {
        contentEL.classList.toggle('active');
        return;
    }
    if (targetEl !== contentEL && !contentEL.contains(targetEl)) {
        contentEL.classList.remove('active');
    }
}, { passive: true });

function initSlider() {
    if (window.innerWidth <= 991 && !window.swiper) {
        window.swiper = new Swiper('.swiper', {
            effect: "coverflow",
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: "auto",
            initialSlide: 1,
            loop: true,
            coverflowEffect: {
                slideShadows: false,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    } else if (window.innerWidth > 991 && window.swiper) {
        window.swiper.destroy();
        window.swiper = null;
    }
}

function createParamsString() {
    const urlParams = new URLSearchParams(window.location.search);
    let paramsString = '';
    const needParams = ['r1', 'r2', 'r3', 'r4', 'utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    urlParams.forEach((val, key) => {
        if (needParams.includes(key)) {
            paramsString += `${key}=${val}&`;
        }
    })

    paramsString = paramsString.slice(0, -1);

    return paramsString.length ? `?${paramsString}` : '';
}

function addCookiesParams(queryParams) {
    const { client, session } = getCookieGA(getCookie('_ga'), getCookie('_ga_4Y05J2NTN4'));
    let param = queryParams ? queryParams : '?';

    if (client) {
        param += param === '?' ? `client_id=${client}` : `&client_id=${client}`;
    }

    if (session) {
        param += param === '?' ? `session_id=${session}` : `&session_id=${session}`;
    }

    return param === '?' ? '' : param;
}

function setParamsToLinks(region) {
    const langLinksElements = document.querySelectorAll('.dropdown-link');
    langLinksElements.forEach((el) => {
        el.href = `${el.href}${window.paramsString}`;
    });

    const SOURCE_URL = window.paramsString.length ? `&SOURCE_URL=${window.location.origin}` : `?SOURCE_URL=${window.location.origin}`;
    const secureLinksElements = document.querySelectorAll('.secure-link');
    secureLinksElements.forEach((el) => {
        el.href = `${window.EXTERNAL_LINKS?.[region].privateLink}${el.dataset.url}${window.paramsString}${SOURCE_URL}`;
    });

    const publicLinksElements = document.querySelectorAll('.public-link');
    publicLinksElements.forEach((el) => {
        el.href = `${window.EXTERNAL_LINKS?.[region].siteUrl}${el.dataset.url}${window.paramsString}`;
    });
}

function getCookieGA(client, session) {
    client = client ? `${client.split('.')[2]}.${client.split('.')[3]}` : undefined;
    session = session ? `${session.split('.')[2]}` : undefined;

    return { client, session };
}

function getCookie(name) {
    let matches = document.cookie
        .match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));

    return matches ? decodeURIComponent(matches[1]) : undefined;
}